import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ProtocolInstancePanel from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/ProtocolInstancePanel';
import { ProtocolItem } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/ProtocolItem';
import doNothing from 'common/lib/doNothing';
import { ConfiguredDevice, Parameter } from 'common/types/bundle';

type Props = {
  inputs: {
    stageId: string;
    parameter: Parameter;
    value: ConfiguredDevice[];
  }[];
  onClose: () => void;
};

export default function ProtocolStageSelectionPanel({ inputs, onClose }: Props) {
  return (
    <ProtocolInstancePanel title="Stage devices" onClose={onClose}>
      <StyledStack>
        {inputs.map(input => (
          <ProtocolItem
            key={input.stageId}
            onToggle={doNothing}
            elementId=""
            parameter={input.parameter}
            value={input.value}
            checked={false}
            isDisabled={false}
          />
        ))}
      </StyledStack>
    </ProtocolInstancePanel>
  );
}

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
  padding: spacing(0, 2),
}));
