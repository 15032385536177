import { useCallback } from 'react';

import { StepParamConfigById } from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import { CreateInputStepState } from 'client/app/apps/protocols/context/StepsProvider/stepState';
import { isDefined } from 'common/lib/data';
import { Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

export function useElementInputs(
  elementInstanceId: string,
  elementInstanceName: string,
  parametersWithValues: { parameter: Parameter; value: any }[],
  stepsConfig: StepParamConfigById,
  activeStepId: string,
  onChange: (value: CreateInputStepState, checked: boolean) => void,
) {
  const configuredElementInputs = getConfiguredElementInputsByStepId(
    elementInstanceId,
    stepsConfig,
  );

  const inputParameters = parametersWithValues.map(({ parameter, value }) => ({
    value,
    ...addConfigContext(
      parameter,
      configuredElementInputs,
      configuredElementInputs[activeStepId]?.stepName ?? '',
    ),
  }));

  const handleChangeInput = useCallback(
    (
      parameter: Parameter,
      checked: boolean,
      editor: ParameterEditorConfigurationSpec,
      value: any,
      key?: string | number,
    ) => {
      const config = parameter.configuration!;
      const entry: CreateInputStepState = {
        element: {
          instanceName: elementInstanceName,
          id: elementInstanceId,
        },
        parameter: {
          name: parameter.name,
          editor,
          displayName: config.displayName,
          extraKey: key,
        },
        value,
      };
      onChange(entry, checked);
    },
    [elementInstanceId, elementInstanceName, onChange],
  );

  return {
    inputParameters,
    handleChangeInput,
  };
}

function addConfigContext(
  parameter: Parameter,
  configuredOutputs: ConfiguredInputsByStepId,
  activeStepName: string,
) {
  const stepMemberships = Object.entries(configuredOutputs)
    .map(([_, config]) => {
      const isParamEnabled = config.inputs[parameter.name] !== undefined;
      return isParamEnabled ? config.stepName : undefined;
    })
    .filter(isDefined);

  const enabledKeys = Object.entries(configuredOutputs).flatMap(([_, config]) => {
    const keys = Object.keys(config.inputs[parameter.name] || {});
    return keys.filter(key => key !== 'noKeyDisplayName');
  });

  return {
    parameter,
    enabledKeys,
    isEnabled: stepMemberships.length > 0,
    otherStepMembership:
      stepMemberships.filter(v => v !== activeStepName).join(', ') || undefined,
  };
}

type ConfiguredInputsByStepId<DisplayName = string> = {
  [stepId: string]: {
    stepName: DisplayName;
    inputs: {
      [paramName: string]: {
        noKeyDisplayName: DisplayName;
        [keyName: string]: DisplayName;
      };
    };
  };
};

function getConfiguredElementInputsByStepId(
  elementInstanceId: string,
  stepsConfig: StepParamConfigById,
): ConfiguredInputsByStepId {
  const entries = Object.entries(stepsConfig)
    .map(([stepId, stepConfig]) => {
      if (!(elementInstanceId in stepConfig.inputs)) {
        return undefined;
      }
      return [
        stepId,
        {
          stepName: stepConfig.name,
          inputs: stepConfig.inputs[elementInstanceId],
        },
      ];
    })
    .filter(isDefined);

  return Object.fromEntries(entries);
}
