import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { v4 as uuid } from 'uuid';

import {
  newStepParamConfig,
  StepParamConfigById,
} from 'client/app/apps/protocols/context/StepsProvider/stepsConfig';
import {
  CreateInputStepState,
  CreateOutputStepState,
  InputStepState,
  newInputStepState,
  newOutputStepState,
  newStepStates,
  OutputStepState,
  StepState,
  updateStepStates,
} from 'client/app/apps/protocols/context/StepsProvider/stepState';
import { useProtocolsParamState } from 'client/app/apps/protocols/lib/utils';
import { Markdown } from 'common/lib/markdown';
import {
  ProtocolStep,
  ProtocolStepInput,
  ProtocolStepOutput,
} from 'common/types/Protocol';
import {
  getElementId,
  isElementPath,
  Schema,
  SchemaInput,
  SchemaOutput,
} from 'common/types/schema';

type StepAnnotation = {
  name?: string;
  description?: string;
};

type StepsContextType = {
  workflowSchema: Schema;
  protocolSteps: ProtocolStep[];
  stepsConfig: StepParamConfigById;
  selectedStep?: ProtocolStep;
  createStep: () => void;
  handleSelectStep: (stepId: string) => void;
  updateStep: (step: ProtocolStep) => (opts: StepAnnotation) => void;
  updateStepInput: (step: ProtocolStep) => (index: number, opts: StepAnnotation) => void;
  updateStepOutput: (step: ProtocolStep) => (index: number, opts: StepAnnotation) => void;
  reorderSteps: (step: ProtocolStep[]) => void;
  reorderStepInputs: (step: ProtocolStep) => (orderedInputIds: string[]) => void;
  linkStepInputs: (step: ProtocolStep) => (indices: number[]) => void;
  unlinkStepInput: (step: ProtocolStep) => (index: number) => void;
  toggleStepInput: (
    step: ProtocolStep,
  ) => (input: CreateInputStepState, checked: boolean) => void;
  toggleStepOutput: (
    step: ProtocolStep,
  ) => (output: CreateOutputStepState, checked: boolean) => void;
  deleteStep: (stepId: string) => void;
  deleteStepInput: (step: ProtocolStep) => (index: number) => void;
  deleteStepOutput: (step: ProtocolStep) => (index: number) => void;
};

export const StepsContext = createContext<StepsContextType | undefined>(undefined);

export const useStepsContext = () => {
  const context = useContext(StepsContext);

  if (context === undefined) {
    throw new Error('useStepsContext must be used within a StepsProvider');
  }

  return context;
};

type StepsProviderProps = {
  schema: Schema;
  steps: ProtocolStep[];
} & PropsWithChildren;

export const StepsProvider: FC<StepsProviderProps> = ({ schema, steps, children }) => {
  // lazy initial state instead of a reducer. The state we manage is not too
  // complex and involves updating and splicing arrays, which isn't more
  // convenient using dispatch updates
  //
  // Workflow schema provides the functional aspect of a step, while protocol
  // (instance) steps provide the presentational aspect. Other components will
  // need to potentially update either entity without triggering re-renders or
  // race conditions. Hence these internal steps are the single source of truth.
  const [stepStates, setStepStates] = useState(() => newStepStates(schema, steps));

  const workflowSchema: Schema = useMemo(() => {
    return {
      inputs: stepStates.flatMap<SchemaInput>(({ inputs }) =>
        inputs.flatMap(({ id, path, linked = [], ...state }) => {
          const allInputs = [{ id, path }, ...linked];
          return allInputs.map(i => ({
            id: i.id,
            typeName: state.typeName,
            path: i.path,
            default: state.default,
            contextId: state.contextId,
          }));
        }),
      ),
      outputs: stepStates.flatMap<SchemaOutput>(({ outputs }) =>
        outputs.map(o => {
          return { id: o.id, typeName: o.typeName, path: o.path };
        }),
      ),
    };
  }, [stepStates]);

  const protocolSteps: ProtocolStep[] = useMemo(() => {
    return stepStates.map(state => {
      return {
        id: state.id,
        displayName: state.displayName,
        displayDescription: state.displayDescription || ('' as Markdown),
        // getElementId(i.path)! since path must be defined and we only support
        // element paths atm
        inputs: state.inputs.map<ProtocolStepInput>(i => {
          return {
            id: i.id,
            elementInstanceId: getElementId(i.path)!,
            displayName: i.displayName,
            displayDescription: i.displayDescription || ('' as Markdown),
            sourceDescription: i.sourceDescription,
            configuration: i.configuration,
            linked: i.linked?.map(({ id, sourceDescription }) => ({
              id,
              sourceDescription,
            })),
          };
        }),
        outputs: state.outputs.map<ProtocolStepOutput>(o => {
          return {
            id: o.id,
            elementInstanceId: getElementId(o.path)!,
            displayName: o.displayName,
            displayDescription: o.displayDescription || ('' as Markdown),
            sourceDescription: o.sourceDescription,
          };
        }),
      };
    });
  }, [stepStates]);

  const stepsConfig = useMemo(() => newStepParamConfig(stepStates), [stepStates]);

  const { selectedStep, handleSelectStep } = useProtocolsParamState(protocolSteps);

  const createStep = () => {
    const id = uuid();
    const displayName = getNewStepName(stepStates);
    setStepStates([
      ...stepStates,
      {
        id,
        displayName,
        inputs: [],
        outputs: [],
      },
    ]);
    handleSelectStep(id);
  };

  const reorderSteps = (orderedSteps: ProtocolStep[]) => {
    const orderedIds = orderedSteps.map(({ id }) => id);
    const result = [...stepStates].sort(
      (a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id),
    );
    setStepStates(result);
  };

  const reorderStepInputs = (step: ProtocolStep) => (orderedInputIds: string[]) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const stepState = stepStates[stateIndex];
      const inputs = [...stepState.inputs].sort(
        (a, b) => orderedInputIds.indexOf(a.id) - orderedInputIds.indexOf(b.id),
      );
      const result = stepStates.toSpliced(stateIndex, 1, { ...stepState, inputs });
      setStepStates(result);
    }
  };

  const linkStepInputs = (step: ProtocolStep) => (indices: number[]) => {
    setStepStates(oldState => {
      const stateIndex = oldState.findIndex(({ id }) => id === step.id);
      if (stateIndex === -1) {
        return oldState;
      }
      const state = oldState[stateIndex];
      const remainder = state.inputs.filter((_, idx) => !indices.includes(idx));
      const selected = state.inputs.filter((_, idx) => indices.includes(idx));
      if (selected.length > 0) {
        const { linked: _oldLinked, ...input } = selected.shift()!;
        const newLinks = selected.map(({ id, path, sourceDescription }) => {
          if (!isElementPath(path)) {
            throw new Error(`only element paths can be linked; got: ${path}`);
          }
          return { id, path, sourceDescription };
        });
        const newInput = { ...input, linked: newLinks };
        const inputs = [...remainder, newInput];
        return oldState.toSpliced(stateIndex, 1, { ...state, inputs });
      }
      return oldState;
    });
  };

  const unlinkStepInput = (step: ProtocolStep) => (index: number) => {
    setStepStates(oldState => {
      const stateIndex = oldState.findIndex(({ id }) => id === step.id);
      if (stateIndex === -1) {
        return oldState;
      }
      const state = oldState[stateIndex];
      const before = state.inputs[index];
      const { linked: _oldLinked, ...input } = before;
      const newInputs = before.linked?.map(({ id, path, sourceDescription }) => {
        return {
          ...input,
          id,
          path,
          displayName: sourceDescription?.displayName ?? '',
          sourceDescription,
        };
      });
      const after = [input, ...(newInputs ?? [])];
      const unlinkedInputs = state.inputs.toSpliced(index, 1, ...after);
      return oldState.toSpliced(stateIndex, 1, {
        ...state,
        inputs: unlinkedInputs,
      });
    });
  };

  const updateStep = (step: ProtocolStep) => (opts: StepAnnotation) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const before = stepStates[stateIndex];
      const after: StepState = {
        ...before,
        displayName: opts.name ?? before.displayName,
        displayDescription: (opts.description as Markdown) ?? before.displayDescription,
      };
      const result = stepStates.toSpliced(stateIndex, 1, after);
      setStepStates(result);
    }
  };

  const updateStepInput =
    (step: ProtocolStep) => (index: number, opts: StepAnnotation) => {
      const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
      if (stateIndex > -1) {
        const before = stepStates[stateIndex].inputs[index];
        const after: InputStepState = {
          ...before,
          displayName: opts.name ?? before.displayName,
          displayDescription: (opts.description as Markdown) ?? before.displayDescription,
        };
        const result = updateStepStates(stepStates, stateIndex, {
          input: { updateByIndex: { index, value: after } },
        });
        setStepStates(result);
      }
    };

  const updateStepOutput =
    (step: ProtocolStep) => (index: number, opts: StepAnnotation) => {
      const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
      if (stateIndex > -1) {
        const before = stepStates[stateIndex].outputs[index];
        const after: OutputStepState = {
          ...before,
          displayName: opts.name ?? before.displayName,
          displayDescription: (opts.description as Markdown) ?? before.displayDescription,
        };
        const result = updateStepStates(stepStates, stateIndex, {
          output: { updateByIndex: { index, value: after } },
        });
        setStepStates(result);
      }
    };

  const toggleStepInput =
    (step: ProtocolStep) => (input: CreateInputStepState, checked: boolean) => {
      setStepStates(oldState => {
        const index = oldState.findIndex(({ id }) => id === step.id);
        if (index === -1 && !input.parameter.editor) {
          return oldState;
        }
        const inputState = newInputStepState(input);
        return updateStepStates(oldState, index, {
          input: {
            add: checked ? inputState : undefined,
            removeByPath: !checked ? inputState.path : undefined,
          },
        });
      });
    };

  const toggleStepOutput =
    (step: ProtocolStep) => (output: CreateOutputStepState, checked: boolean) => {
      setStepStates(oldState => {
        const index = oldState.findIndex(({ id }) => id === step.id);
        if (index === -1) {
          return oldState;
        }
        const outputState = newOutputStepState(output);
        return updateStepStates(oldState, index, {
          output: {
            add: checked ? outputState : undefined,
            removeByPath: !checked ? outputState.path : undefined,
          },
        });
      });
    };

  const deleteStep = (stepId: string) => {
    const result = stepStates.filter(({ id }) => id !== stepId);
    setStepStates(result);
  };

  const deleteStepInput = (step: ProtocolStep) => (index: number) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const result = updateStepStates(stepStates, stateIndex, {
        input: { updateByIndex: { index, value: undefined } },
      });
      setStepStates(result);
    }
  };

  const deleteStepOutput = (step: ProtocolStep) => (index: number) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const result = updateStepStates(stepStates, stateIndex, {
        output: { updateByIndex: { index, value: undefined } },
      });
      setStepStates(result);
    }
  };

  const state = {
    workflowSchema,
    protocolSteps,
    stepsConfig,
    selectedStep,
    createStep,
    handleSelectStep,
    updateStep,
    updateStepInput,
    updateStepOutput,
    reorderSteps,
    reorderStepInputs,
    linkStepInputs,
    unlinkStepInput,
    toggleStepInput,
    toggleStepOutput,
    deleteStep,
    deleteStepInput,
    deleteStepOutput,
  };

  return <StepsContext.Provider value={state}>{children}</StepsContext.Provider>;
};

function getNewStepName(steps: StepState[]): string {
  const existingNames = steps.map(step => step.displayName);
  for (let index = 0; index <= existingNames.length; index++) {
    const displayName = `New Step ${index + 1}`;
    if (!existingNames.some(existingName => existingName === displayName)) {
      return displayName;
    }
  }

  // can't happen, we've tried length+1 names, they can't all exist in the array
  throw new Error('step new name calculation error');
}
