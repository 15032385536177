import React, { useCallback, useEffect, useState } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import Stack from '@mui/material/Stack';

import { InputStepItem } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/StepCard/InputStepContents/InputStepItem';
import { LinkedInputStepItem } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/StepCard/InputStepContents/LinkedInputStepItem';
import { StepContents } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/StepCard/StepContents';
import {
  ArrayAdditionalProps,
  MapAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { isDefined } from 'common/lib/data';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { ProtocolStepInput } from 'common/types/Protocol';
import Button from 'common/ui/components/Button';

type Props = {
  inputs: ProtocolStepInput[];
  onLink: (inputIndices: number[]) => void;
  onUnlink: (inputIndex: number) => void;
  onDelete?: (inputIndex: number) => void;
};

export default function InputStepContents({ inputs, onLink, onUnlink, onDelete }: Props) {
  const [activeEditorKey, setActiveEditorKey] = useState<EditorKey>('');
  const [checkedItems, setCheckedItems] = useState<boolean[]>(
    Array(inputs.length).fill(false),
  );

  const editorCount = countByEditorKey(inputs);

  const disabledItemReasons = inputs.map(input => {
    const editorKey = getEditorKey(input.configuration);
    if (input.linked !== undefined) {
      return 'Already linked to other parameters';
    }
    if (editorCount[editorKey] === 1) {
      return 'Add two or more parameters of this type to link them as one parameter';
    }
    const isActiveType = activeEditorKey === '' || activeEditorKey === editorKey;
    if (!isActiveType) {
      return 'A parameter of another type is already selected';
    }
    return '';
  });

  const handleOnCheck = useCallback((input: ProtocolStepInput, index: number) => {
    setCheckedItems(prev => {
      const next = prev.toSpliced(index, 1, !prev[index]);
      const areNonChecked = next.every(v => v === false);
      setActiveEditorKey(areNonChecked ? '' : getEditorKey(input.configuration));
      return next;
    });
  }, []);

  const canLinkParameters = checkedItems.filter(v => v === true).length > 1;

  const handleLinkParameters = useCallback(() => {
    setCheckedItems(prev => {
      const indicesToGroup = prev
        .map((checked, i) => (checked ? i : undefined))
        .filter(isDefined);
      setActiveEditorKey('');
      onLink(indicesToGroup);
      return prev.map(_ => false);
    });
  }, [onLink]);

  // if inputs are added or removed outside (or inside) the scope of this
  // component, simply reset the local state
  useEffect(() => {
    setActiveEditorKey('');
    setCheckedItems(Array(inputs.length).fill(false));
  }, [inputs.length]);

  return (
    <Stack alignItems="center">
      <StepContents
        items={inputs}
        // it's important to add the index because we always want the rendered
        // items to be reevaluated if index changes. This parent component is in
        // charge of child state
        getItemKey={({ id }, index) => `${id}-${index}`}
        renderItemContents={(input, index) => {
          return input.linked ? (
            <LinkedInputStepItem input={input} onUnlink={() => onUnlink(index)} />
          ) : (
            <InputStepItem
              input={input}
              disabled={disabledItemReasons[index] !== ''}
              tooltip={disabledItemReasons[index]}
              checked={checkedItems[index]}
              onCheck={() => handleOnCheck(input, index)}
            />
          );
        }}
        emptyMessage="Select a parameter from the elements in the workflow."
        onDelete={onDelete}
      />
      {canLinkParameters && (
        <Button
          variant="tertiary"
          color="primary"
          startIcon={<LinkIcon />}
          onClick={handleLinkParameters}
        >
          Link Parameters
        </Button>
      )}
    </Stack>
  );
}

type EditorKey = string;

function countByEditorKey(inputs: ProtocolStepInput[]) {
  const result: { [key: EditorKey]: number } = {};
  for (const { configuration } of inputs) {
    const key = getEditorKey(configuration);
    const previous = result[key] ?? 0;
    result[key] = previous + 1;
  }
  return result;
}

function getEditorKey(configuration?: ParameterEditorConfigurationSpec): EditorKey {
  if (!configuration) {
    return '';
  }
  // compound types are not unique by just the EditorType but also by the value EditorType
  const { type, additionalProps } = configuration;
  switch (type) {
    case EditorType.ARRAY: {
      const arrayProps = additionalProps as ArrayAdditionalProps;
      return `${type}_${getEditorKey(arrayProps.itemEditor)}`;
    }
    case EditorType.MAP: {
      const mapProps = additionalProps as MapAdditionalProps;
      return `${type}_${getEditorKey(mapProps.valueEditor)}`;
    }
    default:
      return `${type}`;
  }
}
