import { useCallback } from 'react';

import { useDeleteProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import useDialog from 'common/ui/hooks/useDialog';

export function useDeleteProtocolInstanceWithConfirmation() {
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const { handleDeleteProtocolInstance } = useDeleteProtocolInstance();
  const handleDeleteProtocolInstanceWithConfirmation = useCallback(
    async (id: ProtocolInstanceId) => {
      const isDeleteConfirmed = await openConfirmationDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'protocol instance',
      });

      if (isDeleteConfirmed) {
        await handleDeleteProtocolInstance(id);
      }
    },
    [handleDeleteProtocolInstance, openConfirmationDialog],
  );

  return {
    confirmationDialog,
    handleDeleteProtocolInstanceWithConfirmation,
  };
}
