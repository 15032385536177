import React, { useCallback } from 'react';

import '@remirror/styles/all.css';

import { TableComponents, TableExtension } from '@remirror/extension-react-tables';
import { i18nFormat } from '@remirror/i18n';
import { EditorComponent, Remirror, ThemeProvider, useRemirror } from '@remirror/react';
import { MarkdownEditor as RemirrorEditor } from '@remirror/react-editors/markdown';
import {
  CommandButtonGroup,
  CreateTableButton,
  HeadingLevelButtonGroup,
  HistoryButtonGroup,
  ListButtonGroup,
  ToggleBlockquoteButton,
  ToggleBoldButton,
  ToggleItalicButton,
  ToggleStrikeButton,
  Toolbar,
  VerticalDivider,
} from '@remirror/react-ui';
import { AllStyledComponent } from '@remirror/styles/emotion';
import { ExtensionPriority } from 'remirror';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  PlaceholderExtension,
  StrikeExtension,
  TrailingNodeExtension,
} from 'remirror/extensions';

import { OnChangeMarkdown } from 'client/app/components/Markdown/OnChangeMarkdown';

type Props = {
  placeholder?: string;
  initialContent: string;
  onChange: (s: string) => void;
};

export function MarkdownEditor2({ placeholder = '', initialContent, onChange }: Props) {
  return (
    <RemirrorEditor placeholder={placeholder} initialContent={initialContent}>
      <OnChangeMarkdown onChange={onChange} />
    </RemirrorEditor>
  );
}

export default function MarkdownEditor({ placeholder, initialContent, onChange }: Props) {
  const extensions = useCallback(
    () => [
      new LinkExtension({ autoLink: true }),
      new PlaceholderExtension({ placeholder }),
      new BoldExtension({}),
      new StrikeExtension(),
      new ItalicExtension(),
      new HeadingExtension({}),
      new BlockquoteExtension(),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new ListItemExtension({
        priority: ExtensionPriority.High,
        enableCollapsible: true,
      }),
      new CodeExtension(),
      new TrailingNodeExtension({}),
      new TableExtension({ resizable: false }),
      new MarkdownExtension({ copyAsMarkdown: false }),
      new CodeBlockExtension({ supportedLanguages: [] }),
      /**
       * `HardBreakExtension` allows us to create a newline inside paragraphs.
       * e.g. in a list item
       */
      new HardBreakExtension(),
    ],
    [placeholder],
  );
  const { manager } = useRemirror({
    extensions,
    stringHandler: 'markdown',
  });

  // AllStyledComponent and ThemeProvider are required for correct styling of
  // the TableExtension, which is why we don't wrap in the same styling that we
  // use for rendering non-editable markdown (see InlineMarkdownEditor)
  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Remirror
          manager={manager}
          i18nFormat={i18nFormat}
          initialContent={initialContent}
        >
          <MarkdownToolbar />
          <EditorComponent />
          <TableComponents />
          <OnChangeMarkdown onChange={onChange} />
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );
}

function MarkdownToolbar() {
  return (
    <Toolbar>
      <HistoryButtonGroup />
      <VerticalDivider />
      <HeadingLevelButtonGroup showAll />
      <VerticalDivider />
      <CommandButtonGroup>
        <ToggleBoldButton />
        <ToggleItalicButton />
        <ToggleStrikeButton />
      </CommandButtonGroup>
      <VerticalDivider />
      <ListButtonGroup />
      <VerticalDivider />
      <CreateTableButton />
      <VerticalDivider />
      <ToggleBlockquoteButton />
    </Toolbar>
  );
}
