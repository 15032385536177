import { isDefined } from 'common/lib/data';
import { Markdown } from 'common/lib/markdown';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

/**
 * Protocol is the JSON type which powers the protocol page.
 */
export type Protocol = {
  displayDescription: Markdown;
  /**
   * steps of the protocol are displayed in the UI in the order inserted in the array.
   */
  steps: ProtocolStep[];
};

export type ProtocolStep = {
  id: string;
  displayName: string;
  displayDescription: Markdown;
  /**
   * inputs of the protocol are displayed in the UI in the order inserted in the array.
   */
  inputs: ProtocolStepInput[];
  /**
   * outputs of the protocol are displayed in the UI in the order inserted in the array.
   */
  outputs: ProtocolStepOutput[];
};

export type ProtocolStepInput<SchemaInputId = string> = {
  /**
   * id refers to the corresponding SchemaInput id defined in the workflow
   */
  id: SchemaInputId;
  elementInstanceId: string;
  displayName: string;
  displayDescription: Markdown;
  /**
   * configuration is used to show the correct input parameter editor to use in the UI.
   */
  configuration: ParameterEditorConfigurationSpec;
  sourceDescription?: SourceDescription;
  /**
   * linked describes other SchemaInputs that should have the same values as
   * the current ProtocolStepInput, and as a result do not need to be displayed
   */
  linked?: { id: SchemaInputId; sourceDescription?: SourceDescription }[];
};

/**
 * SourceDescription provides context of where the input comes from. This is
 * useful to help debugging and guiding the user after they rename and alter
 * the input for a protocol
 */
export type SourceDescription = {
  displayName: string;
  /** only relevant if the source is an element */
  elementInstanceName?: string;
  /**
   * e.g. human readable keys within the object of the source or indices of an
   * array from the source
   */
  extraDescriptions?: string[];
};

export type ProtocolStepOutput = {
  /**
   * id refers to the corresponding SchemaOutput id defined in the workflow
   */
  id: string;
  elementInstanceId: string;
  displayName: string;
  displayDescription: Markdown;
  sourceDescription?: SourceDescription;
};

/*
 * returns the default initial Protocol
 */
export function getDefaultProtocol(): Protocol {
  return {
    displayDescription: '' as Markdown,
    steps: [],
  };
}

/** @returns string representation of SourceDescription */
export function describeSourceDescription(
  v?: SourceDescription,
  delimiter: string = '\n',
) {
  if (!v) return '';

  const descriptions = [
    `Original Name: ${v.displayName}`,
    v.elementInstanceName ? `Element Name: ${v.elementInstanceName}` : undefined,
    v.extraDescriptions?.join(delimiter),
  ];
  return descriptions.filter(isDefined).join(delimiter);
}
