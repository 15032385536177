import React from 'react';

import { DevicesEntrySelector } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/ParameterEntrySelector/DevicesEntrySelector';
import { EntrySelector } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/ParameterEntrySelector/EntrySelector';
import {
  ArrayAdditionalProps,
  MapAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { ConfiguredDevice } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

type Props = {
  elementInstanceId: string;
  editor: ParameterEditorConfigurationSpec;
  value: any;
  enabledKeys?: string[];
  onToggle: (
    checked: boolean,
    editor: ParameterEditorConfigurationSpec,
    value: any,
    key: string | number,
  ) => void;
  isDisabled: boolean;
};

export default function ParameterEntrySelector({
  elementInstanceId,
  editor,
  value,
  enabledKeys,
  onToggle,
  isDisabled,
}: Props) {
  if (value === null || value === undefined) {
    return null;
  }

  if (editor.type === EditorType.MAP) {
    const props = editor.additionalProps as MapAdditionalProps;
    const entries = value as { [key: string]: any };
    return (
      <EntrySelector
        elementInstanceId={elementInstanceId}
        entries={entries}
        enabledKeys={enabledKeys}
        valueEditor={props.valueEditor!}
        onToggle={onToggle}
        showKeys
        isDisabled={isDisabled}
      />
    );
  }

  if (editor.type === EditorType.ARRAY) {
    const props = editor.additionalProps as ArrayAdditionalProps;
    const entries = value as any[];
    return (
      <EntrySelector
        elementInstanceId={elementInstanceId}
        entries={Object.fromEntries(entries.map((v, i) => [`${i}`, v]))}
        enabledKeys={enabledKeys}
        valueEditor={props.itemEditor!}
        onToggle={(checked, editor, entryValue, key) =>
          onToggle(checked, editor, entryValue, +key)
        }
        showKeys={false}
        isDisabled={isDisabled}
      />
    );
  }

  if (editor.type === EditorType.DEVICE_CONFIGURATION) {
    const devices = value as ConfiguredDevice[];
    return <DevicesEntrySelector value={devices} isDisabled={isDisabled} />;
  }

  // wrapped to keep scaling proportional for non-selectable values
  return (
    <EntrySelector
      elementInstanceId={elementInstanceId}
      entries={{ entry: value }}
      valueEditor={editor}
      showKeys={false}
      isDisabled
    />
  );
}
