import React from 'react';

import Stack from '@mui/material/Stack';

import { EntrySelector } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel/ParameterEntrySelector/EntrySelector';
import {
  DECKSELECTION,
  DEVICESELECTIONS,
  INPUTPLATES,
  TIPTYPES,
} from 'client/app/lib/workflow/workflowConfigProperties';
import doNothing from 'common/lib/doNothing';
import { ConfiguredDevice } from 'common/types/bundle';
import {
  isDeviceThatCanPerformLiquidHandling,
  isLiquidHandlerDevice,
} from 'common/types/bundleConfigUtils';

type Props = {
  value: ConfiguredDevice[];
  isDisabled: boolean;
};

export function DevicesEntrySelector({ value: devices, isDisabled }: Props) {
  const mainDevice = devices.find(isDeviceThatCanPerformLiquidHandling);
  const isComplexLiquidHandler = mainDevice && isLiquidHandlerDevice(mainDevice);
  return (
    <Stack spacing={4}>
      {/*
       * DEVICE_SELECTION is just to display the actively selected device.
       * We don't want to allow users to select devices but never configure them
       */}
      <EntrySelector
        elementInstanceId=""
        entries={{ [DEVICESELECTIONS.configuration!.displayName]: devices }}
        valueEditor={DEVICESELECTIONS.configuration!.editor}
        isDisabled
        showKeys={false}
      />
      {isComplexLiquidHandler && (
        <>
          <EntrySelector
            elementInstanceId=""
            entries={{ [DECKSELECTION.configuration!.displayName]: mainDevice }}
            valueEditor={DECKSELECTION.configuration!.editor}
            onToggle={doNothing}
            showKeys
            isDisabled={isDisabled}
          />
          <EntrySelector
            elementInstanceId=""
            entries={{
              [TIPTYPES.configuration!.displayName]: mainDevice.tipTypes,
            }}
            valueEditor={TIPTYPES.configuration!.editor}
            onToggle={doNothing}
            showKeys
            isDisabled={isDisabled}
          />
        </>
      )}
      {mainDevice && (
        <EntrySelector
          elementInstanceId=""
          entries={{
            [INPUTPLATES.configuration!.displayName]: mainDevice.inputPlateTypes,
          }}
          valueEditor={INPUTPLATES.configuration!.editor}
          onToggle={doNothing}
          showKeys
          isDisabled={isDisabled}
        />
      )}
    </Stack>
  );
}
