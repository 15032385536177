import React, { useRef, useState } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import { QUERY_PROTOCOLS } from 'client/app/api/gql/queries';
import ProtocolsCategoriesFetcher, {
  QueryVariables,
} from 'client/app/apps/protocols/ProtocolsList/ProtocolCategoriesFetcher';
import { ProtocolListActionProvider } from 'client/app/apps/protocols/ProtocolsList/ProtocolsListActionProvider';
import DataComponent from 'client/app/apps/protocols/ProtocolsList/ProtocolsListDataComponent';
import NoDataComponent from 'client/app/apps/protocols/ProtocolsList/ProtocolsListNoDataComponent';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

type Props = {
  hidden: boolean;
  currentUserId: string | undefined;
  searchQuery?: string;
  queryVariables: QueryVariables;
};

const ProtocolsList = ({ hidden, currentUserId, searchQuery, queryVariables }: Props) => {
  const [tagQuery, setTagQuery] = useState('');
  const variables = {
    search: searchQuery ?? undefined,
    tagName: tagQuery ?? undefined,
    ...queryVariables,
  };

  const protocolsQuery = useQuery(QUERY_PROTOCOLS, {
    variables,
  });

  const pageInfo = protocolsQuery.data?.protocols.pageInfo as PageInfo | undefined;

  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const hasNextPage = usePagination({
    entity: 'protocols',
    pageInfo,
    fetchMore: protocolsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: protocolsQuery.loading,
    variables: {},
  });

  return (
    <Wrapper hidden={hidden}>
      <TagsWrapper>
        <ProtocolsCategoriesFetcher
          variables={queryVariables}
          selectedTag={tagQuery}
          onSelectTag={setTagQuery}
        />
      </TagsWrapper>
      <Contents>
        <ProtocolListActionProvider>
          <ContainerWithIntersectionBar
            scrollableRef={scrollableContainerRef}
            noHeader
            dense
            content={
              <RenderQuery
                query={protocolsQuery}
                renderData={DataComponent}
                renderNoData={NoDataComponent}
                additionalDataProps={{
                  currentUserId,
                }}
                loadingComponent={EntityCardSkeletonList}
                additionalNoDataProps={{ searchQuery }}
                emptyCondition={data => data.protocols.items.length === 0}
              />
            }
          />
        </ProtocolListActionProvider>
        {hasNextPage && (
          <LoadingContainer>
            <CircularProgress size={24} />
          </LoadingContainer>
        )}
      </Contents>
    </Wrapper>
  );
};

const Wrapper = styled('div', { shouldForwardProp: p => p !== 'hidden' })<{
  hidden: boolean;
}>(({ hidden }) => ({
  display: hidden ? 'none' : 'contents',
}));

const TagsWrapper = styled('div')({
  gridArea: 'sidebar',
  overflowY: 'hidden',
});

const Contents = styled('div')({
  gridArea: 'contents',
  overflowY: 'hidden',
});

const LoadingContainer = styled('div')(() => ({
  width: 'fit-content',
  margin: '0 auto',
}));

export default ProtocolsList;
