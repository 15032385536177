import { useCallback } from 'react';

import { Reference, useMutation, useQuery } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  COPY_PROTOCOL,
  COPY_PROTOCOL_INSTANCE,
  CREATE_PROTOCOL,
  CREATE_PROTOCOL_INSTANCE,
  CREATE_PROTOCOL_VERSION,
  DELETE_PROTOCOL,
  DELETE_PROTOCOL_INSTANCE,
  PUBLISH_PROTOCOL,
  UPDATE_PROTOCOL,
  UPDATE_PROTOCOL_INSTANCE,
  UPDATE_PROTOCOL_WORKFLOW,
} from 'client/app/api/gql/mutations';
import {
  QUERY_PROTOCOL,
  QUERY_PROTOCOL_INSTANCE,
  QUERY_PROTOCOL_VERSIONS,
} from 'client/app/api/gql/queries';
import {
  CopyProtocolInstanceMutation,
  CreateProtocolInstanceMutation,
  CreateProtocolMutation,
  CreateProtocolVersionMutation,
  DeleteProtocolInstanceMutation,
  DeleteProtocolMutation,
  DeleteProtocolsWithIdMutation,
  graphql,
  PublishProtocolMutation,
} from 'client/app/gql';
import {
  experimentsRoutes,
  protocolInstanceRoutes,
  protocolsRoutes,
} from 'client/app/lib/nav/actions';
import { Protocol } from 'common/types/Protocol';
import { ParameterMap } from 'common/types/schema';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

/**
 * Queries a protocol instance.
 *
 * @returns Protocol instance and loading state.
 */
export function useQueryProtocolInstance(protocolInstanceId: ProtocolInstanceId) {
  const { data, loading, error } = useQuery(QUERY_PROTOCOL_INSTANCE, {
    variables: { id: protocolInstanceId },
  });
  return { data, loading, error };
}

/**
 * Queries a protocol
 *
 * @returns Protocol and loading state.
 */
export function useQueryProtocol(protocolId: ProtocolId, version: ProtocolVersion) {
  const { data, loading, error } = useQuery(QUERY_PROTOCOL, {
    variables: { id: protocolId, version },
  });
  return { data, loading, error };
}

/**
 * Creates a new protocol from an existing workflow.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCreateProtocol() {
  const [createProtocol, { loading }] = useMutation(CREATE_PROTOCOL);

  const handleCreateProtocol = useCallback(
    async (
      name: string,
      workflowId: WorkflowId,
    ): Promise<CreateProtocolMutation['createProtocol']> => {
      const updateResult = await createProtocol({
        variables: {
          input: {
            name,
            workflowId,
          },
        },
      });

      return getResultOrThrow(
        updateResult,
        'Create protocol',
        data => data.createProtocol,
      );
    },
    [createProtocol],
  );

  return { handleCreateProtocol, loading };
}

export function useQueryProtocolVersions(id: ProtocolId) {
  const { loading, data, error } = useQuery(QUERY_PROTOCOL_VERSIONS, {
    variables: {
      id,
    },
  });

  return { loading, data, error };
}

/**
 * Creates a new version of an existing protocol
 *
 * @returns Handler for creating new version and loading state.
 */
export function useCreateProtocolVersion() {
  const [createProtocolVersion, { loading }] = useMutation(CREATE_PROTOCOL_VERSION);

  const handleCreateProtocolVersion = async (
    id: ProtocolId,
    version: ProtocolVersion,
    opt?: { deleteExistingEditableVersions?: boolean },
  ): Promise<CreateProtocolVersionMutation['createProtocolVersion']> => {
    const updateResult = await createProtocolVersion({
      variables: {
        input: {
          id,
          version,
          deleteExistingEditableVersions: opt?.deleteExistingEditableVersions,
        },
      },
      refetchQueries: [{ query: QUERY_PROTOCOL_VERSIONS, variables: { id } }],
    });

    return getResultOrThrow(
      updateResult,
      'Create Protocol Version',
      data => data.createProtocolVersion,
    );
  };

  return { handleCreateProtocolVersion, loading };
}

/**
 * Creates a copy  of the protocol
 *
 * @returns Handler for copying protocol and loading state.
 */
export function useCopyProtocol() {
  const [copyProtocol, { loading }] = useMutation(COPY_PROTOCOL);

  const handleCopyProtocol = useCallback(
    async (
      id: ProtocolId,
      version: ProtocolVersion,
    ): Promise<CreateProtocolMutation['createProtocol']> => {
      const updateResult = await copyProtocol({
        variables: {
          input: {
            id,
            version,
          },
        },
      });

      return getResultOrThrow(updateResult, 'Copy protocol', data => data.copyProtocol);
    },
    [copyProtocol],
  );

  return { handleCopyProtocol, loading };
}

/**
 * Creates a new protocol instance from an existing protocol.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstance() {
  const [createProtocolInstance, { loading }] = useMutation(CREATE_PROTOCOL_INSTANCE);
  const handleCreateProtocolInstance = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ): Promise<CreateProtocolInstanceMutation['createProtocolInstance']> => {
    const updateResult = await createProtocolInstance({
      variables: {
        input: {
          protocolId: protocolId,
          protocolVersion: protocolVersion,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Create protocol instance',
      data => data.createProtocolInstance,
    );
  };

  return { handleCreateProtocolInstance, loading };
}

/**
 * Creates a new protocol from an existing workflow and navigates
 * to the editing route for that new protocol.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCreateProtocolAndNavigate() {
  const { handleCreateProtocol, loading } = useCreateProtocol();
  const { navigate } = useNavigation();

  const handleCreateProtocolAndNavigate = useCallback(
    async (name: string, workflowId: WorkflowId) => {
      const updateResult = await handleCreateProtocol(name, workflowId);
      if (updateResult?.protocol) {
        navigate(protocolsRoutes.editProtocol, {
          id: updateResult.protocol.id,
          version: '1',
        });
      }
    },
    [handleCreateProtocol, navigate],
  );

  return { handleCreateProtocolAndNavigate, loading };
}

/**
 * Creates a copy of the protocol instance
 *
 * @returns Handler for copying protocol instance and loading state.
 */
export function useCopyProtocolInstance() {
  const [copyProtocolInstance, { loading }] = useMutation(COPY_PROTOCOL_INSTANCE);

  const handleCopyProtocolInstance = useCallback(
    async (
      id: ProtocolInstanceId,
    ): Promise<CopyProtocolInstanceMutation['copyProtocolInstance']> => {
      const updateResult = await copyProtocolInstance({
        variables: { input: { id } },
      });

      return getResultOrThrow(
        updateResult,
        'Copy protocol instance',
        data => data.copyProtocolInstance,
      );
    },
    [copyProtocolInstance],
  );

  return { handleCopyProtocolInstance, loading };
}

/**
 * Creates a copy of the protocol instance and navigates to the editing route
 * for that new protocol instance.
 *
 * @returns Handler for copying protocol instance and loading state.
 */
export function useCopyProtocolInstanceAndNavigate() {
  const { handleCopyProtocolInstance, loading } = useCopyProtocolInstance();
  const { navigate } = useNavigation();
  const handleCopyProtocolInstanceAndNavigate = useCallback(
    async (id: ProtocolInstanceId) => {
      const updateResult = await handleCopyProtocolInstance(id);
      if (updateResult?.protocolInstance) {
        navigate(protocolInstanceRoutes.editProtocolInstance, {
          id: updateResult.protocolInstance.id,
        });
      }
    },
    [handleCopyProtocolInstance, navigate],
  );
  return { handleCopyProtocolInstanceAndNavigate, loading };
}

/**
 * Creates a new version of an existing protocol and navigates
 * to the editing route for that new version.
 *
 * @returns Handler for creating new version and loading state.
 */
export function useCreateProtocolVersionAndNavigate() {
  const { handleCreateProtocolVersion, loading } = useCreateProtocolVersion();
  const { navigate } = useNavigation();
  const handleCreateProtocolVersionAndNavigate = async (
    id: ProtocolId,
    version: ProtocolVersion,
    opt?: { deleteExistingEditableVersions?: boolean },
  ) => {
    const result = await handleCreateProtocolVersion(id, version, opt);
    if (result?.protocol) {
      navigate(protocolsRoutes.editProtocol, {
        id: result.protocol.id,
        version: result.protocol.version,
      });
    }
    return result;
  };

  return { handleCreateProtocolVersionAndNavigate, loading };
}

/**
 * Creates a copy of the protocol from an existing workflow and navigates
 * to the editing route for that new protocol.
 *
 * @returns Handler for creating protocol and loading state.
 */
export function useCopyProtocolAndNavigate() {
  const { handleCopyProtocol, loading } = useCopyProtocol();
  const { navigate } = useNavigation();
  const handleCopyProtocolAndNavigate = useCallback(
    async (id: ProtocolId, version: ProtocolVersion) => {
      const updateResult = await handleCopyProtocol(id, version);
      if (updateResult?.protocol) {
        navigate(protocolsRoutes.editProtocol, {
          id: updateResult.protocol.id,
          version: '1',
        });
      }
    },
    [handleCopyProtocol, navigate],
  );

  return { handleCopyProtocolAndNavigate, loading };
}

/**
 * Creates a new protocol instance from an existing protocol and navigates
 * to the editing route for that new protocol instance.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function useCreateProtocolInstanceAndNavigate() {
  const { handleCreateProtocolInstance, loading } = useCreateProtocolInstance();
  const { navigate } = useNavigation();
  const handleCreateProtocolInstanceAndNavigate = async (
    protocolId: ProtocolId,
    protocolVersion: ProtocolVersion,
  ) => {
    const updateResult = await handleCreateProtocolInstance(protocolId, protocolVersion);
    if (updateResult?.protocolInstance) {
      navigate(protocolInstanceRoutes.editProtocolInstance, {
        id: updateResult.protocolInstance.id,
      });
    }
  };

  return { handleCreateProtocolInstanceAndNavigate, loading };
}

/**
 * Publishes a protocol
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function usePublishProtocol() {
  const [publishProtocol, { loading }] = useMutation(PUBLISH_PROTOCOL);
  const handlePublishProtocol = async (
    id: ProtocolId,
    version: ProtocolVersion,
    isPublic: boolean,
    tagsToAdd?: string[],
  ): Promise<PublishProtocolMutation['publishProtocol']> => {
    const updateResult = await publishProtocol({
      variables: {
        input: {
          id,
          version,
          isPublic,
          tagsToAdd,
        },
      },
      refetchQueries: [{ query: QUERY_PROTOCOL_VERSIONS, variables: { id } }],
    });

    return getResultOrThrow(
      updateResult,
      'Publish protocol',
      data => data.publishProtocol,
    );
  };

  return { handlePublishProtocol, loading };
}

/**
 * Publishes a protocol and navigates
 * to the protocols list.
 *
 * @returns Handler for creating protocol instance and loading state.
 */
export function usePublishProtocolAndNavigate() {
  const { handlePublishProtocol, loading } = usePublishProtocol();
  const { navigate } = useNavigation();
  const handlePublishProtocolAndNavigate = async (
    id: ProtocolId,
    version: ProtocolVersion,
    isPublic: boolean,
    tagsToAdd?: string[],
  ) => {
    const updateResult = await handlePublishProtocol(id, version, isPublic, tagsToAdd);
    if (updateResult?.protocol) {
      navigate(experimentsRoutes.protocols, undefined);
    }
  };

  return { handlePublishProtocolAndNavigate, loading };
}

export type ProtocolUpdate = {
  protocol?: Protocol;
  name?: string;
  shortDescription?: string;
  exampleSimulationId?: SimulationId;
};

/**
 * Deletes the protocol
 *
 * @returns Handler for deleting protocol and loading state.
 */
export function useDeleteProtocol() {
  const [deleteProtocol, { loading }] = useMutation<DeleteProtocolMutation>(
    DELETE_PROTOCOL,
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            protocols(existingProtocols = {}, { readField }) {
              const items = existingProtocols.items || [];

              const updatedItems = items.filter(
                (itemRef: Reference) =>
                  !(
                    readField('id', itemRef) === data?.deleteProtocol?.protocol.id &&
                    readField('version', itemRef) ===
                      data?.deleteProtocol?.protocol.version
                  ),
              );

              return {
                ...existingProtocols,
                items: updatedItems,
              };
            },
          },
        });
      },
      optimisticResponse: variables => {
        const id = variables.input.id;
        const version = variables.input.version;

        return {
          __typename: 'Mutation',
          deleteProtocol: {
            __typename: 'CreateProtocolOutput',
            protocol: {
              __typename: 'Protocol',
              id,
              version,
              isDeleted: true,
            },
          },
        };
      },
    },
  );

  const handleDeleteProtocol = async (id: ProtocolId, version: ProtocolVersion) => {
    await deleteProtocol({
      variables: {
        input: {
          id,
          version,
        },
      },
      refetchQueries: [{ query: QUERY_PROTOCOL_VERSIONS, variables: { id } }],
    });
  };
  return { handleDeleteProtocol, loading };
}

export const DELETE_PROTOCOLS_WITH_ID = graphql(/* GraphQL */ `
  mutation DeleteProtocolsWithId($input: DeleteProtocolsWithIdInput!) {
    deleteProtocolsWithId(input: $input) {
      protocols {
        id
        version
        isDeleted
      }
    }
  }
`);

/**
 * Deletes all Protocols with a matching ID
 *
 * @returns Handler for deleting protocol and loading state.
 */
export function useDeleteProtocolsWithId() {
  const [deleteProtocolsWithId, { loading }] = useMutation<DeleteProtocolsWithIdMutation>(
    DELETE_PROTOCOLS_WITH_ID,
  );

  const handleDeleteProtocolsWithId = async (
    id: ProtocolId,
    opts?: { includeUnpublished?: boolean },
  ) => {
    await deleteProtocolsWithId({
      variables: {
        input: {
          id,
          includeUnpublished: opts?.includeUnpublished,
        },
      },
      update(cache) {
        cache.modify({
          fields: {
            protocols(existingProtocols = {}, { readField }) {
              const items = existingProtocols.items || [];

              const updatedItems = items.filter((itemRef: Reference) => {
                const itemId = readField('id', itemRef);
                const itemPublished = readField('isPublished', itemRef);
                return itemId !== id || (!opts?.includeUnpublished && !itemPublished);
              });

              return {
                ...existingProtocols,
                items: updatedItems,
              };
            },
          },
        });
      },
    });
  };
  return { handleDeleteProtocolsWithId, loading };
}

/**
 * Updates the protocol
 *
 * @returns Handler for updating protocol and loading state.
 */
export function useUpdateProtocol(id: ProtocolId, version: ProtocolVersion) {
  const [updateProtocol, { loading }] = useMutation(UPDATE_PROTOCOL);

  const handleUpdateProtocol = async (editVersion: number, values: ProtocolUpdate) => {
    await updateProtocol({
      variables: {
        input: {
          id,
          version,
          editVersion,
          ...values,
        },
      },
    });
  };
  return { handleUpdateProtocol, loading };
}

/**
 * Updates the workflow
 *
 * @returns Handler for updating workflow and loading state.
 */
export function useUpdateWorkflow() {
  const [updateProtocolWorkflow, { loading }] = useMutation(UPDATE_PROTOCOL_WORKFLOW);

  const handleUpdateWorkflow = async (
    id: WorkflowId,
    version: number,
    workflow: WorkflowBlob,
  ) => {
    await updateProtocolWorkflow({
      variables: {
        input: {
          id,
          version,
          workflow,
        },
      },
    });
  };
  return { handleUpdateWorkflow, loading };
}

type ProtocolInstanceUpdate = {
  name?: string;
  params?: ParameterMap;
};

/**
 * Updates the protocol instance with parameters or name
 *
 * @returns Handler for updating protocol instance and loading state.
 */
export function useUpdateProtocolInstance(id: ProtocolInstanceId) {
  const [updateProtocolInstance, { loading }] = useMutation(UPDATE_PROTOCOL_INSTANCE);

  const handleUpdateProtocolInstance = async (
    editVersion: number,
    opts: ProtocolInstanceUpdate = {},
  ) => {
    const { name, params } = opts;
    const updateResult = await updateProtocolInstance({
      variables: {
        input: {
          id,
          name,
          editVersion,
          params,
        },
      },
    });

    return getResultOrThrow(
      updateResult,
      'Update protocol instance',
      data => data.updateProtocolInstance,
    );
  };

  return { handleUpdateProtocolInstance, loading };
}

/**
 * Deletes the protocol instance
 *
 * @returns Handler for deleting protocol instance and updating state.
 */
export function useDeleteProtocolInstance() {
  const [deleteProtocolInstance, { loading }] =
    useMutation<DeleteProtocolInstanceMutation>(DELETE_PROTOCOL_INSTANCE, {
      update(cache, { data }) {
        cache.modify({
          fields: {
            protocolInstances(existingInstances = {}, { readField }) {
              const items = existingInstances.items || [];

              const updatedItems = items.filter(
                (itemRef: Reference) =>
                  !(
                    readField('id', itemRef) ===
                    data?.deleteProtocolInstance?.protocolInstance.id
                  ),
              );

              return {
                ...existingInstances,
                items: updatedItems,
              };
            },
          },
        });
      },
      optimisticResponse: variables => {
        const id = variables.input.id;

        return {
          __typename: 'Mutation',
          deleteProtocolInstance: {
            __typename: 'ProtocolInstanceOutput',
            protocolInstance: {
              __typename: 'ProtocolInstance',
              id,
              isDeleted: true,
            },
          },
        };
      },
    });

  const handleDeleteProtocolInstance = useCallback(
    async (id: ProtocolInstanceId) => {
      await deleteProtocolInstance({
        variables: {
          input: {
            id,
          },
        },
      });
    },
    [deleteProtocolInstance],
  );
  return { handleDeleteProtocolInstance, loading };
}
