import React from 'react';

import Box from '@mui/material/Box';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';

type Props = {
  showVersion?: boolean;
};

const RecentProtocolInstancesListHeader = ({ showVersion }: Props) => {
  return (
    <Header>
      <Background />
      <HeaderCell gridColumn="name">
        <Typography variant="overline">Recent Instances</Typography>
      </HeaderCell>
      <HeaderCell gridColumn="status" justifyContent="center">
        <Typography variant="overline">Status</Typography>
      </HeaderCell>
      {showVersion && (
        <HeaderCell gridColumn="version">
          <Typography variant="overline">Version</Typography>
        </HeaderCell>
      )}
      <HeaderCell gridColumn="author">
        <Typography variant="overline">Created by</Typography>
      </HeaderCell>
      <HeaderCell gridColumn="date">
        <Typography variant="overline">Creation Date</Typography>
      </HeaderCell>
    </Header>
  );
};

export const styledRow = (f: (theme: Theme, showVersion?: boolean) => any) => {
  return styled(Box, { shouldForwardProp: p => p !== 'showVersion' })<{
    showVersion?: boolean;
  }>(({ theme, showVersion }) => f(theme, showVersion));
};

const Background = styled('div')({
  gridRow: 1,
  gridColumn: '1 / -1',
  backgroundColor: Colors.GREY_20,
  height: '100%',
});

const HeaderCell = styled(Box, {
  shouldForwardProp: p => p !== 'gridColumn' && p !== 'justifyContent',
})<{ gridColumn: string; justifyContent?: string }>(({ gridColumn, justifyContent }) => ({
  display: 'flex',
  alignItems: 'center',
  gridRow: 1,
  gridColumn,
  height: '48px',
  overflow: 'hidden',
  justifyContent,
}));

const Header = styled('div')({
  display: 'contents',
});

export { RecentProtocolInstancesListHeader };
